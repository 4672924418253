import { Container } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../../components/Layout";
import PageHeadingSection from "../../components/PageHeadingSection";
import Card from "../../components/Card";
import { splittedUID } from "../../utils/sortPages";
import { standardToName } from "../../utils/standardToName";

export default function Certifications({ data }) {
  let certificationsInfo = data.allPrismicCertifications.nodes;

  certificationsInfo = certificationsInfo
    .map(node => {
      const { standard } = splittedUID(node.uid);
      return { ...node, standard };
    })
    .sort((a, b) => a.standard - b.standard);

  const image = data.allFile.nodes[0];

  return (
    <Layout>
      <PageHeadingSection title={"Certifications"} />
      <Container maxW={"7xl"} py={5}>
        <Flex wrap={"wrap"} justifyContent={{ base: "center" }}>
          {certificationsInfo.map(info => {
            return (
              <Card
                title={info.data.title.text}
                path={`/certifications/${info.uid}`}
                imgData={
                  info.data.featured_image.gatsbyImageData || getImage(image)
                }
                name={standardToName(info.standard)}
              />
            );
          })}
        </Flex>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query AllCertificationPages {
    allPrismicCertifications {
      nodes {
        uid
        data {
          title {
            text
          }
          featured_image {
            gatsbyImageData
          }
        }
      }
    }

    allFile(
      filter: {
        relativeDirectory: { eq: "certifications" }
        name: { eq: "itsms" }
      }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 400)
        }
      }
    }
  }
`;
